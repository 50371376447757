





















import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component({})
export default class List extends Vue {
  data = {}

  save() {
    const data = JSON.parse(JSON.stringify(this.data))
    if (data.reminderDaysBefore === '') {
      data.reminderDaysBefore = null
    }
    if (this.isNew) {
      this.$axios
        .post(this.url, data)
        .then((response) => {
          this.data = response.data.data
          vxm.booking.fetchCalendars()
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    } else {
      this.$axios
        .put(this.url, data)
        .then((response) => {
          this.data = response.data.data
          vxm.booking.fetchCalendars()
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  cancel() {
    this.$router.back()
  }

  mounted() {
    if (this.isNew) {
      this.data = {}
    } else {
      this.$axios
        .get(this.url)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error fetching calendar')
        })
    }
  }

  get isNew() {
    return this.$route.meta.isNew
  }

  get url() {
    const url = '/v4/site/calendars'
    return this.isNew ? url : url + '/' + this.$route.params.calendarId
  }
}
